import { Link } from 'gatsby'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import CashbackBanner from 'src/components/CashbackComponents/CashbackBanner'
import { CashbackFaq } from 'src/components/CashbackComponents/CashbackFaq'
import { CashbackRules } from 'src/components/CashbackComponents/CashbackRules'
import { CashbackUse } from 'src/components/CashbackComponents/CashbackUse'
import BackArrowIcon from 'src/components/ui/Breadcrumb/BackArrowIcon'
import { useMobile } from 'src/hooks/useMobile'

import 'src/components/CashbackComponents/cashbackStyle.scss'

const Cashback = () => {
  const { screenWidth } = useMobile()

  return (
    <>
      <GatsbySeo title="Cashback | Decathlon" />
      <section className="cashback-breadcrumb flex ppp:gap-2 bg-white ppp:p-2 pp:p-4 ppp:text-xs pp:text-base sm:py-4 sm:px-6 md:px-12 md:py-6">
        <button
          className="ppp:text-xs pp:text-sm pp:mr-4 md:mr-6"
          onClick={() => window.history.back()}
        >
          <BackArrowIcon className="inline-block mr-1" />
          <span className="font-semibold text-blue">Voltar</span>
        </button>
        <span>
          {screenWidth > 1023 ? (
            <>
              <Link to="/">Home</Link> /{' '}
              <Link to="/clube">Clube Decathlon</Link> /
            </>
          ) : (
            <>
              <Link to="/clube">...Clube Decathlon</Link> /
            </>
          )}
          <span className="text-blue"> Cashback</span>
        </span>
      </section>
      <CashbackBanner />
      <CashbackRules />
      <CashbackUse />
      <CashbackFaq />
    </>
  )
}

export default Cashback
