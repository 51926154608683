export const CashbackUse = () => {
  const useModes = [
    {
      section: '1',
      title: 'Faça login com sua conta Decathlon',
    },
    {
      section: '2',
      title: 'Na etapa de pagamento selecione a opção Utilizar Cashback',
    },
    {
      section: '3',
      title:
        'O valor* já será aplicado como desconto e você poderá finalizar sua compra!',
    },
  ]

  return (
    <section className="cashback-use bg-blue ppp:px-2 pp:px-4 sm:px-28 ppp:py-8 sm:pt-8 sm:pb-12 md:pt-12 md:pb-[70px]">
      <h2 className="text-center text-white uppercase italic font-bold ppp:text-3xl pp:text-[42px] ppp:mb-7 pp:mb-8 md:text-6xl">
        como usar
      </h2>
      <div className="cashback-use__items flex flex-col mx-auto ppp:gap-7 ppp:mb-2 pp:mb-4 md:max-w-[658px] md:mb-6">
        {useModes.map((mode) => {
          return (
            <div
              key={mode?.title}
              className="cashback-use__term flex ppp:gap-2 pp:gap-4 ppp:px-4 pp:px-10 items-center md:gap-12"
            >
              <div className="cashback-use__term--section text-white font-bold italic ppp:text-4xl pp:text-6xl md:text-5xl">
                {mode?.section}
              </div>
              <div className="cashback-use__term--text text-white font-bold ppp:text-base pp:text-[20px] md:text-[26px]">
                {mode?.title}
              </div>
            </div>
          )
        })}
      </div>
      <div className="cashback-use__obs flex ppp:gap-2 ppp:px-4 pp:px-10 md:max-w-[658px] md:mx-auto md:pl-28 md:pr-0">
        <div className="cashback-use__obs--symbol text-white text-xs ppp:w-5 ppp:h-5 pp:w-6 pp:h-6">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-white"
          >
            <g clipPath="url(#clip0_1952_16934)">
              <path d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20ZM11 7H13V9H11V7ZM11 11H13V17H11V11Z" />
            </g>
            <defs>
              <clipPath id="clip0_1952_16934">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div className="cashback-use__obs--text text-white ppp:text-[10px] pp:text-sm">
          Desconto limitado a 20% do valor total dos itens.
        </div>
      </div>
    </section>
  )
}
