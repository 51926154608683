import { Pagination, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/css'
import 'swiper/css/pagination'

export const CashbackRules = () => {
  const contents = [
    {
      section: '1',
      title: 'Escolha seus produtos nas lojas físicas, site ou aplicativo*',
      content:
        'Válido apenas para produtos vendidos e entregues por Decathlon.',
    },
    {
      section: '2',
      title: 'Faça login com sua conta Decathlon. ',
      content: 'Para lojas físicas, apenas se identifique com o CPF.',
    },
    {
      section: '3',
      title:
        'A cada compra, você ganhará 5% de volta para gastar nas lojas físicas, site ou aplicativo',
    },
    {
      section: '4',
      title: 'Após 24h da emissão da nota fiscal, seu saldo estará disponível.',
      content: 'O saldo possui prazo de expiração de 60 dias.',
    },
  ]

  return (
    <section className="cashback-rules bg-white ppp:px-2 pp:px-4 ppp:pt-5 ppp:pb-[30px] md:px-12 md:pt-8 md:pb-16">
      <h2 className="uppercase font-bold italic text-center text-[#344450] ppp:text-2xl pp:text-[42px] ppp:mb-7 md:pb-12">
        como ganhar
      </h2>
      <Swiper
        className="cashback-rules__carousel min-w-[226px]"
        modules={[Pagination, Navigation]}
        navigation
        pagination={{ clickable: true }}
        breakpoints={{
          1024: {
            slidesPerView: 4,
            spaceBetween: 24,
          },
          767: {
            slidesPerView: 2,
          },
          240: {
            slidesPerView: 1,
          },
        }}
      >
        {contents.map((content) => {
          return (
            <SwiperSlide
              key={content?.title}
              className="md:min-h-[508px] md:border md:border-[#B3BAC3] md:rounded-lg md:py-16 md:px-8"
            >
              <div className="cashback-rules__carousel__header flex ppp:flex-row ppp:gap-3 pp:gap-4 ppp:mb-4 ppp:items-center pp:justify-center md:flex-col md:gap-8 md:mb-8">
                <span className="cashback-rules__carousel__header--section text-[#0082C3] font-bold italic ppp:text-6xl md:text-8xl md:mr-auto">
                  {content?.section}
                </span>
                <h3 className="cashback-rules__carousel__header--title font-bold text-[#001018] ppp:text-lg pp:text-[20px] pp:pr-10 ppp:leading-6 md:text-3xl">
                  {content?.title}
                </h3>
              </div>
              <div className="cashback-rules__carousel__content flex ppp:justify-evenly pp:justify-center ppp:gap-2 ppp:px-2 pp:pl-6 pp:pr-20 sm:pl-8 sm:pr-16 md:text-sm md:pl-0 md:pr-4">
                {content?.content && (
                  <>
                    <span className="cashback-rules__carousel__content--symbol">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_1952_16934)">
                          <path
                            d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20ZM11 7H13V9H11V7ZM11 11H13V17H11V11Z"
                            fill="#1A2A34"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1952_16934">
                            <rect width="24" height="24" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                    <span className="cashback-rules__carousel__content--text text-[#1A2A34] ppp:text-xs pp:text-sm">
                      {content?.content}
                    </span>
                  </>
                )}
              </div>
            </SwiperSlide>
          )
        })}
      </Swiper>
    </section>
  )
}
