import { useState } from 'react'
import { Link } from 'gatsby'

import ArrowIcon from '../ClubComponents/ArrowIcon'
import cashbacks from './cashback-faq.json'

export const CashbackFaq = () => {
  const [showContent, setShowContent] = useState<string[]>([])

  const handleShowButton = (btn: string): void => {
    if (!showContent.includes(btn)) {
      setShowContent((prevState) => [...prevState, btn])

      return
    }

    const aux = showContent.filter((item) => item !== btn)

    setShowContent(aux)
  }

  return (
    <section className="cashback-faq bg-white ppp:py-10 ppp:px-2 pp:px-4 sm:px-[116px]">
      <Link
        target="_blank"
        to="https://decathlonstore.vteximg.com.br/arquivos/regulamento-clube-decathlon.pdf"
        className="ppp:w-full block border text-blue font-bold text-center max-w-[328px] mx-auto ppp:text-sm ppp:py-2 border-[#D9DDE1] rounded-[4px] pp:text-base"
        rel="noreferrer"
      >
        Leia o regulamento
      </Link>
      <h2 className="text-center uppercase italic font-bold text-[#344450] ppp:text-3xl ppp:mt-10 ppp:mb-8 pp:text-[42px] md:text-6xl">
        ficou com alguma dúvida?
      </h2>
      <div className="cashback-faq__questions mx-auto md:max-w-[744px]">
        {cashbacks.map((cashback) => {
          return (
            <div
              key={cashback?.title}
              className="cashback-faq__questions__item border-b-[1px] border-[#E5E5E5]"
            >
              <button
                onClick={() => handleShowButton(cashback?.title)}
                className="cashback-faq__questions__item--title w-full text-left font-bold text-[#1A2A34] ppp:text-xs pp:text-base flex ppp:gap-2 justify-between ppp:px-2 ppp:pb-2 ppp:pt-4"
              >
                {cashback?.title}
                <div>
                  <ArrowIcon
                    direction={`${
                      showContent.includes(cashback?.title) ? 'up' : 'down'
                    }`}
                    size={3}
                    thickness={2}
                  />
                </div>
              </button>
              <div
                key={cashback?.title}
                className={`cashback-faq__questions__item--content flex flex-col ppp:gap-4 ppp:px-2 ppp:pb-4 ppp:pt-2 pp:mt-8 ${
                  showContent.includes(cashback?.title) ? 'block' : 'hidden'
                }`}
              >
                {cashback?.content.map((content) => {
                  return (
                    <p
                      key={content}
                      className="ppp:text-xs pp:text-base"
                      dangerouslySetInnerHTML={{ __html: content }}
                    />
                  )
                })}
              </div>
            </div>
          )
        })}
      </div>
    </section>
  )
}
