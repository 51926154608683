/* eslint-disable @typescript-eslint/no-explicit-any */
import type { SVGAttributes } from 'react'

export default function BackArrowIcon({ ...rest }: SVGAttributes<any>) {
  return (
    <svg
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M4.3335 4.66669V7.33335L0.333496 4.00002L4.3335 0.666687V3.33335H7.66683C9.08132 3.33335 10.4379 3.89526 11.4381 4.89545C12.4383 5.89564 13.0002 7.2522 13.0002 8.66669C13.0002 10.0812 12.4383 11.4377 11.4381 12.4379C10.4379 13.4381 9.08132 14 7.66683 14H1.66683V12.6667H7.66683C8.7277 12.6667 9.74511 12.2453 10.4953 11.4951C11.2454 10.745 11.6668 9.72755 11.6668 8.66669C11.6668 7.60582 11.2454 6.58841 10.4953 5.83826C9.74511 5.08811 8.7277 4.66669 7.66683 4.66669H4.3335Z"
        fill="#007DBC"
      />
    </svg>
  )
}
